import React, {useMemo} from 'react';
import {connectRefinementList} from 'react-instantsearch-dom';

import SearchList from './SearchList';

const IncludeRefinementList = ({
  limit,
  items,
  renderLabel = (label) => label,
  refine,
  searchForItems,
  attribute,
  currentRefinement,
  isFromSearch,
  defaultRefinment,
  onRefine = (item, attribute) => {},
  ...otherProps
}) => {
  const filteredItems = useMemo(() => items.filter((item) => item.label[0] !== '-'), [items]);
  const handleSelected = (item) => {
    refine(item.value);
    onRefine(item, attribute);
  };
  return (
    <SearchList
      name={`${attribute}-include-facet`}
      isFromSearch={isFromSearch}
      items={filteredItems}
      placeholder={otherProps.placeholder}
      renderLabel={renderLabel}
      className={otherProps.className}
      onClick={searchForItems}
      onChange={searchForItems}
      onSelected={handleSelected}
    />
  );
};

const MemoizedIncludeRefinementList = React.memo(IncludeRefinementList);
export default connectRefinementList(MemoizedIncludeRefinementList);
