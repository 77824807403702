import {post} from './Request';
import {BehaviorSubject} from 'rxjs';

// customized algolia search client
class SearchClient {
  constructor() {
    this.requestSubject = new BehaviorSubject();
    this.lastRequest = null;
    this.lastResponse = null;
  }
  // This method is from Algolia to encode each property in query params
  _encode(format, ...args) {
    let i = 0;
    return format.replace(/%s/g, () => encodeURIComponent(args[i++]));
  }

  // This method is from Algolia to serialize the query params
  _serializeQueryParameters(parameters) {
    const isObjectOrArray = (value) =>
      Object.prototype.toString.call(value) === '[object Object]' ||
      Object.prototype.toString.call(value) === '[object Array]';

    return Object.keys(parameters)
      .map((key) =>
        this._encode(
          '%s=%s',
          key,
          isObjectOrArray(parameters[key]) ? JSON.stringify(parameters[key]) : parameters[key]
        )
      )
      .join('&');
  }

  transformNegativeFacetFilters(requests) {
    const newRequest = [...requests];
    for (const request of newRequest) {
      const facetFilters = request?.params?.facetFilters;
      if (!facetFilters) {
        continue;
      }
      const newFacetFilters = [];
      for (const facetFilter of facetFilters) {
        if (facetFilter && facetFilter.length > 0) {
          const negFilters = [];
          const posFilters = [];
          for (const each of facetFilter) {
            if (each.indexOf(':-') > -1) {
              negFilters.push(each);
            } else {
              posFilters.push(each);
            }
          }
          negFilters.forEach((filter) => newFacetFilters.push([filter]));
          if (posFilters.length > 0) {
            newFacetFilters.push(posFilters);
          }
        }
      }
      request.params.facetFilters = newFacetFilters;
    }
    return newRequest;
  }

  // match algolia index seach
  async search(requests) {
    const transformedRequests = this.transformNegativeFacetFilters(requests);
    this.requestSubject.next(transformedRequests);

    const newRequests = transformedRequests.map((x) => {
      return {...x, params: this._serializeQueryParameters(x.params)};
    });

    const requestKey = JSON.stringify(newRequests);
    if (this.lastRequest === requestKey) {
      return this.lastResponse;
    }

    const response = await post(
      `${process.env.REACT_APP_APIM_URL}/Algolia/Indexes/Queries`,
      JSON.stringify({requests: newRequests})
    ).then((response) => response.data);

    this.lastRequest = requestKey;
    this.lastResponse = response;

    return response;
  }

  // match algolia facet search
  async searchForFacetValues(requests) {
    const result = await Promise.all(
      requests.map(async (query) => {
        const indexName = query.indexName;
        const {facetName, facetQuery, ...params} = query.params;
        return post(
          `${process.env.REACT_APP_APIM_URL}/Algolia/Facets/Queries`,
          {
            ...params,
            facetQuery,
          },
          {
            params: {
              indexName,
              facetName,
            },
          }
        ).then((response) => {
          return response.data;
        });
      })
    );
    return result;
  }
}

const searchClient = new SearchClient();
export default searchClient;
export {SearchClient};
