import React, {useMemo} from 'react';
import {connectRefinementList} from 'react-instantsearch-dom';
import SearchList from './SearchList';

const ExcludeRefinementList = ({
  limit, // The limit number need to 2 times of the limit you want to be in the result list, as for the excludsion, it duplicates the same attribute with '-' pre tag.
  items,
  refine,
  renderLabel = (label) => label,
  searchForItems,
  attribute,
  isFromSearch,
  currentRefinement,
  defaultRefinment,
  onRefine = (item, attribute) => {},
  ...otherProps
}) => {
  const filteredItems = useMemo(
    () =>
      items
        .filter((item) => item.label[0] !== '-')
        .slice(0, limit / 2 + 1)
        .reduce((acc, item) => {
          const isNegativeRefined = item.value.indexOf('-' + item.label) > -1;
          const valueWithoutItself = item.value.filter(
            (value) => value !== item.label && value !== '-' + item.label
          );
          if (item.isRefined && !isNegativeRefined) {
            return acc;
          }
          const value = isNegativeRefined
            ? valueWithoutItself
            : [...valueWithoutItself, '-' + item.label];
          return [...acc, {...item, value, isRefined: isNegativeRefined}];
        }, []),
    [items, limit]
  );

  const handleSelect = (item) => {
    refine(item.value);
    onRefine(item, attribute);
  };

  return (
    <SearchList
      name={`${attribute}-exclude-facet`}
      isFromSearch={isFromSearch}
      items={filteredItems}
      placeholder={otherProps.placeholder}
      renderLabel={renderLabel}
      className={otherProps.className}
      onClick={searchForItems}
      onChange={searchForItems}
      onSelected={handleSelect}
    />
  );
};

const MemoizedExcludeRefinementList = React.memo(ExcludeRefinementList);
export default connectRefinementList(MemoizedExcludeRefinementList);
