import React, {FC, useState, useEffect, useCallback, useContext, useRef} from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import {alertService, defaultAlertId, AlertType} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector, useAppDispatch} from '../../../app/store';
import {
  selectDealsTemplateModalOpen,
  selectMixAndMatchTypeChangeLoading,
} from '../../../app/selectors';
import {
  setDealsTemplateModalOpen,
  setMixAndMatchTypeChangeLoading,
} from '../../../app/dealsReducer';
import {get, put, post, api} from '../../../utils/Request';
import {
  MixAndMatchGroupsResponse,
  ValidMixAndMatchFormValues,
  MixAndMatchFormBag,
  EditMixAndMatchAmountRequest,
} from '../../../types';
import {ErrorBox} from '../../Alert';
import {LinkButton} from '../../Button';
import RaisedClaimWarning from '../../Claim/RaisedClaimWarning';
import ActiveClaimWarning from '../../Claim/ActiveClaimWarning';
import FieldGrid from '../../Form/FieldGrid';
import FormStep from '../../Form/FormStep';
import StepperFormActionSection from '../../Form/StepperFormActionSection';
import DownloadTemplatesModal from '../../Modal/DownloadTemplatesModal';
import {isBundleType, mnmDisabled} from '../mixAndMatchUtils';
import MixAndMatchTypeSelect from './MixAndMatchTypeSelect';
import MixAndMatchGroups from './MixAndMatchGroups';

type Props = {
  step: number;
  title: string;
  validationSchema: any;
  totalStep: number;
  onBack: (values: ValidMixAndMatchFormValues, bag: MixAndMatchFormBag) => void;
  onNext: (values: ValidMixAndMatchFormValues, bag: MixAndMatchFormBag) => void;
  style?: React.CSSProperties;
};

type ValuesErrorMap = {
  [key: number]: Partial<{
    products: string;
    amount: string;
    quantity: string;
    quantityLimit: string;
    claimOnGroup: string;
  }>;
};
const claimOnGroupIndex = 0;
export const validateGroups = (values: ValidMixAndMatchFormValues): ValuesErrorMap => {
  const errors: ValuesErrorMap = {};
  const {groups, groupAmounts, type} = values;
  if (!groups || !type) {
    return errors;
  }
  const typeInputConfig = Consts.MixAndMatchTypeMapping[type]?.groupInputConfig;

  if (!typeInputConfig) {
    return errors;
  }

  const requiresClaimOnGroup = (groups?.length ?? 0) > 1;
  if (requiresClaimOnGroup) {
    const hasClaimOnGroup = groups.some(
      (group) =>
        !!group.claimOnGroup && group.claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.NoClaim
    );

    if (!hasClaimOnGroup) {
      errors[claimOnGroupIndex] = errors[claimOnGroupIndex] ?? {};
      errors[claimOnGroupIndex][
        'claimOnGroup'
      ] = `At least one Product Group needs to have the "Claim against this product/ group of
        products?" switched to "Yes".`;
    }
  }

  return groups.reduce((acc: ValuesErrorMap, group, index) => {
    // validate products
    if (!group?.products?.data || group.products.data?.length === 0) {
      acc[group.id] = acc[group.id] ?? {};
      acc[group.id]['products'] = 'Please save at least one deal value before continuing.';
    }
    // validate groupAmounts
    const groupAmount = groupAmounts?.find((x) => x.id === group.id);
    const inputConfig = typeInputConfig[index];
    const isFreeAmount =
      type &&
      [
        Consts.MixAndMatchTypeEnum.BuyAGetBFree,
        Consts.MixAndMatchTypeEnum.BuyNOfAGetOneFree,
        Consts.MixAndMatchTypeEnum.BuyAPlusBGetCFree,
      ].includes(type);
    if (inputConfig?.showDiscount && !isFreeAmount) {
      if (!groupAmount?.amount && groupAmount?.amount !== 0) {
        acc[group.id] = acc[group.id] ?? {};
        acc[group.id]['amount'] = Consts.ValidationMessage.Required;
      } else if (Number(groupAmount.amount) <= 0) {
        acc[group.id] = acc[group.id] ?? {};
        acc[group.id]['amount'] = Consts.ValidationMessage.PositiveValue;
      } else if (group.amountType === Consts.AmountTypeEnum.Percentage) {
        if (Number(groupAmount.amount) > 100 || Number(groupAmount.amount) < -100) {
          acc[group.id] = acc[group.id] ?? {};
          acc[group.id]['amount'] = Consts.ValidationMessage.PercentageValueWith100;
        }
      }
    }
    if (inputConfig?.showQuantity) {
      if (!groupAmount?.quantity && groupAmount?.quantity !== 0) {
        acc[group.id] = acc[group.id] ?? {};
        acc[group.id]['quantity'] = Consts.ValidationMessage.Required;
      } else if (
        [
          Consts.MixAndMatchTypeEnum.BuyNOfAGetPercentageOff,
          Consts.MixAndMatchTypeEnum.BuyNOffAForDollar,
        ].includes(type)
      ) {
        if (Number(groupAmount.quantity) < 2) {
          acc[group.id] = acc[group.id] ?? {};
          acc[group.id]['quantity'] = 'Quantity must be 2 or greater.';
        } else if (Number(groupAmount.quantity) === 0) {
          acc[group.id] = acc[group.id] ?? {};
          acc[group.id]['quantity'] = Consts.ValidationMessage.PositiveValue;
        }
      }
    }
    if (inputConfig?.showQuantityLimit) {
      if (!groupAmount?.quantityLimit && groupAmount?.quantityLimit !== 0) {
        acc[group.id] = acc[group.id] ?? {};
        acc[group.id]['quantityLimit'] = Consts.ValidationMessage.Required;
      } else if (Number(groupAmount.quantityLimit) <= 0) {
        acc[group.id] = acc[group.id] ?? {};
        acc[group.id]['quantityLimit'] = Consts.ValidationMessage.PositiveValue;
      }
    }
    return acc;
  }, errors);
};

const StepMixAndMatchValues: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  onBack,
  onNext,
}) => {
  const [errors, setErrors] = useState<ValuesErrorMap>({});
  const [preValidation, setPreValidation] = useState(true);

  const existingGroupsChecked = useRef(false);

  const templatesModalOpen = useAppSelector(selectDealsTemplateModalOpen);
  const typeChangeLoading = useAppSelector(selectMixAndMatchTypeChangeLoading);

  const dispatch = useAppDispatch();

  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    values: {id, hasRaisedClaim, hasActiveClaim, type, description, groupAmounts, amount},
    setFieldValue,
  } = bag;

  const {
    actions: {disableUpdateOnNextStepTwo},
  } = mnmDisabled(bag.values);
  const isBundle = isBundleType(type);
  const groupSkeletonCount = !!type
    ? Consts.MixAndMatchTypeMapping[type]?.groupInputConfig.length
    : 0;
  const {showLoading, hideLoading} = useContext(LoadingContext);

  // group state errors, not input errors
  const handleScrollToError = useCallback(() => {
    const claimOnGroupError = document.getElementById('mixandmatch-claimOnGroup-errorbox');
    if (claimOnGroupError) {
      claimOnGroupError.scrollIntoView({inline: 'center', block: 'start', behavior: 'smooth'});
    } else {
      const firstProductError = document.querySelectorAll(
        '[data-testid=mixandmatch-products-errorbox]'
      )?.[0];
      if (firstProductError) {
        firstProductError.scrollIntoView({inline: 'center', block: 'start', behavior: 'smooth'});
      }
    }
  }, []);

  useEffect(() => {
    if (!preValidation) {
      handleScrollToError();
      setPreValidation(true);
    }
  }, [preValidation, handleScrollToError, setPreValidation]);

  const loadGroups = useCallback(
    async (mixAndMatchId: number) => {
      try {
        showLoading();
        dispatch(setMixAndMatchTypeChangeLoading(true));
        const response: AxiosResponse<MixAndMatchGroupsResponse> = await get(
          api(Consts.Api.MixAndMatchGroups.replace(':id', `${mixAndMatchId}`))
        );
        if (!type && !!response.data.type) {
          // backend sets type to string value "Unknown" as default value
          const saneType = response.data.type === 'Unknown' ? '' : response.data.type;
          setFieldValue('type', saneType);
        }
        // backend sets amount to 0 as default value
        const saneAmount = response.data.amount === 0 ? null : response.data.amount;
        setFieldValue('amount', saneAmount);
        setFieldValue('groups', response.data.data);
        setFieldValue('amountType', response.data.amountType);

        const groupAmounts = response.data.data?.map(({id, amount, quantity, quantityLimit}) => ({
          id,
          ...(!amount && amount !== 0 ? {} : {amount}),
          ...(!quantity && quantity !== 0 ? {} : {quantity}),
          ...(!quantityLimit && quantityLimit !== 0 ? {} : {quantityLimit}),
        }));
        setFieldValue('groupAmounts', groupAmounts);
        alertService.clear(defaultAlertId);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load mix and match groups', response: error.response},
        });
      } finally {
        dispatch(setMixAndMatchTypeChangeLoading(false));
        hideLoading();
      }
    },
    [type, setFieldValue, showLoading, hideLoading, dispatch]
  );

  useEffect(() => {
    if (id && !existingGroupsChecked.current) {
      loadGroups(id);
    }
    // fetch on initial page load only, otherwise fetch on type change
    existingGroupsChecked.current = true;
  }, [id, loadGroups]);

  const updateGroupAmounts = async () => {
    try {
      const requestBody: EditMixAndMatchAmountRequest = {
        ...((groupAmounts ?? []).length > 0 ? {groups: groupAmounts} : {}),
        ...(amount !== undefined && isBundle ? {mixAndMatchAmount: amount} : {}),
      };
      if (Object.keys(requestBody).length === 0) {
        return;
      }
      showLoading();
      const response: AxiosResponse<MixAndMatchGroupsResponse> = await put(
        api(Consts.Api.MixAndMatchAmounts.replace(':id', `${id}`)),
        requestBody
      );
      setFieldValue('groups', response.data.data);
      setFieldValue('amount', response.data.amount);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to update group amounts', response: error.response},
      });
    } finally {
      hideLoading();
    }
  };

  const createFinanceDetails = async () => {
    try {
      showLoading();
      await post(api(Consts.Api.MixAndMatchFinanceDetails.replace(':id', `${id}`)));
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to create finance details', response: error.response},
      });
    } finally {
      hideLoading();
    }
  };

  const handleNext = async (values: ValidMixAndMatchFormValues, bag: MixAndMatchFormBag) => {
    const validation = validateGroups(values);
    setErrors(validation);
    if (Object.keys(validation).length > 0) {
      setPreValidation(false);
      return;
    }

    if (!disableUpdateOnNextStepTwo) {
      await updateGroupAmounts();
      await createFinanceDetails();
    }
    onNext(values, bag);
  };

  const renderHeader = () => (
    <>
      <LinkButton
        type="button"
        onClick={() => {
          dispatch(setDealsTemplateModalOpen(true));
        }}
      >
        Download Excel Templates
      </LinkButton>
      <DownloadTemplatesModal
        title="Download Excel Templates"
        fileNames={['Mix and Match Bulk Upload Template.xlsx']}
        open={templatesModalOpen}
        onClose={() => {
          dispatch(setDealsTemplateModalOpen(false));
        }}
      />
    </>
  );

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Box sx={{fontWeight: 'bold'}}>Deal name:</Box>
        <Box>{description}</Box>
      </Stack>
      <Stack direction="row" spacing={1} sx={{paddingBottom: '2rem'}}>
        <Box sx={{fontWeight: 'bold'}}>Deal ID:</Box>
        <Box>{id}</Box>
      </Stack>
      <FormStep step={step} title={title} renderHeader={renderHeader}>
        <ActiveClaimWarning
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
          entityActionType="Mix & Match"
        />
        {hasRaisedClaim ? <RaisedClaimWarning /> : null}
        <FieldGrid item xs={12}>
          <MixAndMatchTypeSelect />
        </FieldGrid>
        {!!errors[claimOnGroupIndex] ? (
          <FieldGrid item xs={12} sx={{marginBottom: '2rem', marginTop: '-2rem'}}>
            <ErrorBox
              id="mixandmatch-claimOnGroup-errorbox"
              type={AlertType.Warning}
              severity="error"
              sx={{
                border: 'solid 1px #D0021B',
                paddingTop: '0rem',
                paddingBottom: '0rem',
                width: 'fit-content',
                svg: {
                  width: '1.5rem',
                },
              }}
            >
              {errors[claimOnGroupIndex]['claimOnGroup']}
            </ErrorBox>
          </FieldGrid>
        ) : null}
        {typeChangeLoading ? (
          <FieldGrid item xs={12}>
            <Stack direction="column" gap="2rem">
              {Array.from({length: groupSkeletonCount}).map((_, index) => (
                <Skeleton
                  key={index}
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height={360}
                  sx={{borderRadius: '1rem'}}
                />
              ))}
            </Stack>
          </FieldGrid>
        ) : (
          <MixAndMatchGroups errors={errors} />
        )}
        <StepperFormActionSection
          handleBack={onBack}
          handleNext={handleNext}
          step={step}
          totalStep={totalStep}
        />
      </FormStep>
    </>
  );
};

export default StepMixAndMatchValues;
